<form (ngSubmit)="onSubmit()" *ngIf="showForm">
  <label for="firstName">First Name:</label>
  <input type="text" id="firstName" name="firstName" placeholder="Enter your first name" [(ngModel)]="firstName"
    required>

  <label for="lastName">Last Name:</label>
  <input type="text" id="lastName" name="lastName" placeholder="Enter your last name" required [(ngModel)]="lastName">

  <label for="email">E-Mail:</label>
  <input type="text" id="email" name="email" placeholder="user@organization.com" required [(ngModel)]="email"
    required="">
  
  <label for="roleType">Role Type</label>
  <select id="roleType" name="roleType" [(ngModel)]="roleType">
    <option value="OrgLegRep">Legal Representative</option>
    <option value="FedAdmin">Federation Administrator</option>
  </select>

  <label for="role">Organization</label>
  <select id="role" name="role" [(ngModel)]="organization">
    <option *ngFor="let org of organizations" [value]="org">{{ org }}</option>
  </select>

  <label for="subjectDid">Your DID: (do not change for testing)</label>
  <input type="text" id="subjectDid" name="subjectDid" placeholder="Enter your DID" [(ngModel)]="subjectDid">

  <button type="submit">Submit</button>
</form>

<div *ngIf="connectionId && connectionStatus !== 'trusted' && !showForm">
  <qrcode [qrdata]="invitationUrl" [width]="512" [errorCorrectionLevel]="'M'"></qrcode>
  Scan this code to connect.

  Connection status: {{connectionStatus}}
</div>

<div *ngIf="connectionStatus === 'trusted' && credentialId === ''">
  Connection sucessfull, your credential has been requested.
</div>

<div *ngIf="credentialId">
  Connection sucessfull, your credential has been requested.<br>
  Credential state: {{credentialState}}
</div>